import Scene from "./3d/scene"
import Player from "./3d/player"
import Assets from "./3d/assets"
import Animator from "./animations/animator"
import Processor from "./data/processor"
import ActivityNotifier from "./events/dispatch/activity"
import Subtitles from "./events/dispatch/subtitles"
import PlayController from './events/listen/play'
import FreezeController from './events/listen/freeze'
import Timeline from "./timeline/consecutive"
import Ticker from "./timeline/ticker"


export class Avatar {
  constructor(params = { id: null, domElement: null, modelUrl: null, baseUrl: null }) {
    this.params = params
    
    // use constant id or generate random
    this.id = params.id ?? Math.random().toString(36).substring(2, 15)

    this.scene = new Scene()
    this.player = new Player(this.scene)
    this.assets = new Assets(params.baseUrl)

    this.timeline = new Timeline('processor')
    this.ticker = new Ticker(this.timeline, this.player)
    this.animator = new Animator(this.player.mixer)
    
    this.activityNotifier = new ActivityNotifier(this.id, this.timeline)
    this.subtitles = new Subtitles(this.id)

    this.processor = new Processor({
      animator: this.animator,
      subtitler: this.subtitles,
      ticker: this.ticker,
      assets: this.assets
    })

    this.playController = new PlayController(this.id, this.processor)
    this.freezeController = new FreezeController(this.id, this.player)

    this.scene.addToElement(params.domElement)

    return this
  }

  async init() {
    await this.addModel(this.params.modelUrl)
    this.player?.play()

    return this
  }

  async addModel(url) {
    const model = await this.assets.get(url)
    if (this.scene) {
      this.scene.add_scene(model.scene)
      this.animator.setIdleAnimation(model.animations[0])
    }
  }

  dispose() {
    this.player.stop()
    this.animator.dispose()
    this.animator = undefined

    this.player.dispose()
    this.player = undefined
    
    this.scene.dispose()
    this.scene = undefined

    this.assets.dispose()
    this.assets = undefined

    this.activityNotifier.dispose()
    this.activityNotifier = undefined

    this.subtitles.dispose()
    this.subtitles = undefined

    this.playController.dispose()
    this.freezeController.dispose()
    
    this.processor = undefined
    this.ticker = undefined

    this.params = undefined
  }
}
