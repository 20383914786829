export default class {
    constructor(element, avatarId) {
        this.element = element
        this.avatarId = avatarId
    }

    connect() {
        this.listeners = {
            playing: this.show.bind(this),
            idle: this.hide.bind(this)
        }
        document.addEventListener('adaptis.avatar.activity.playing', this.listeners['playing'])
        document.addEventListener('adaptis.avatar.activity.idle', this.listeners['idle'])
    }

    show(e) {
        if (e.detail.avatar_id != this.avatarId) return
        this.element.style.opacity = 1
        this.unfreeze()
    }

    hide(e) {
        if (e.detail.avatar_id != this.avatarId) return
        this.element.style.opacity = 0
        this.freeze()
    }

    freeze() {
        document.dispatchEvent(new CustomEvent('adaptis.avatar.rendering.freeze', {
            detail: {
                avatarId: this.avatarId
            }
        }))
    }

    unfreeze() {
        document.dispatchEvent(new CustomEvent('adaptis.avatar.rendering.unfreeze', {
            detail: {
                avatarId: this.avatarId
            }
        }))
    }

    disconnect() {
        document.removeEventListener('adaptis.avatar.activity.playing', this.listeners['playing'])
        document.removeEventListener('adaptis.avatar.activity.idle', this.listeners['idle'])
    }
}