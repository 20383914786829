export default class {
    static cache = new Map();
    
    constructor(config) {
        this.api = config.api
        this.abortController = undefined
    }

    play(command, avatarId) {    
        document.dispatchEvent(new CustomEvent('adaptis.avatar.play', {
            detail: {
                avatarId: avatarId,
                data: command
            }
        }))
    }

    async translateId(id, avatarId) {
        this.refreshAbortController()
        const response = await fetch(this.api.id.replace('_id_', id),
            {
                signal: this.abortController.signal
            }
        )

        if (!response.ok) {
            console.error('Translation network error:', response)
        }
        
        this.play(await response.json(), avatarId)
    }

    async translateMixed(text, avatarId) {
        this.refreshAbortController()
        const response = await fetch(this.api.default,
            {
                method: 'POST',
                signal: this.abortController.signal,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mixed: text
                })
            }
        )
        this.play((await response.json()), avatarId)
    }

    async translateText(text, avatarId) {
        this.refreshAbortController()
        const response = await fetch(this.api.default,
            {
                method: 'POST',
                signal: this.abortController.signal,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    text: text
                })
            }
        )
        this.play((await response.json()), avatarId)
    }

    refreshAbortController() {
        if (this.abortController) {
            this.abortController.abort()
        }
        this.abortController = new AbortController()
    }

}
