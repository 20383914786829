import Slot from './slot'

export default class extends Slot {
    constructor(id, start=0) {
        super(id)
        this.start = start
        this._children = []
    }

    reset() {
        super.reset()
        for (const child of this._children) {
            child.reset()
        }
        this._children = []
    }

    tick(time) {
        super.tick(time)
        
        for (const child of this._children) {
            child.tick(time - this.start)
        }
    }

    push(child) {
        this._children.push(child)
    }

    getStartTime() {
        return this.start
    }

    getDuration() {
        let duration = 0
        for (const child of this._children) {
            duration = Math.max(duration, child.getEndTime())
        }
        return duration
    }
}