import Slot from './slot'

export default class extends Slot {
    constructor(id, start, duration) {
        super(id)
        this.start = start
        this.duration = duration
    }

    getStartTime() {
        return this.start
    }

    getDuration() {
        return this.duration
    }
}
