export default class extends EventTarget {
    constructor(id) {
        super()
        this.id = id
        this._previous_time = null
    }

    reset() {
        this.emit('reset')
    }

    tick(time) {
        if (this.isIn(time) && !this.isIn(this._previous_time)) {
            this.emit('in', time)
        } else if (!this.isIn(time) && this.isIn(this._previous_time)) {
            this.emit('out', time)
        }

        this._previous_time = time
    }

    isIn(time) {
        if (!time) {
            return false
        }

        return time >= this.getStartTime() && time < this.getEndTime()
    }

    emit(name, time=0) {
        this.dispatchEvent(new CustomEvent(name, {
            bubbles: false,
            detail: {
                time: time,
                slot: this
            }
        }))
    }

    getStartTime() {
        return 0
    }

    getDuration() {
        return 0
    }

    getEndTime() {
        return this.getStartTime() + this.getDuration()
    }
    
}