export default class {
    constructor(translator) {
        this.listeneres = {
            'click': this.click.bind(this)
        }

        this.attributes = ['id', 'mixed', 'text']
        this.base = 'data-adaptis-translation'

        this.translator = translator
        this.avatarId = 'adaptis-clippit'
    }

    connect() {
        document.addEventListener('click', this.listeneres['click'])
    }

    disconnect() {
        document.removeEventListener('click', this.listeneres['click'])
    }

    click(event) {
        const element = this.#getTarget(event.target)
        if (!element) return

        if (element.hasAttribute(this.#fullAttribute('id'))) {
            return this.translator.translateId(element.getAttribute(this.#fullAttribute('id')), this.avatarId)
        }

        if (element.hasAttribute(this.#fullAttribute('mixed'))) {
            return this.translator.translateMixed(element.getAttribute(this.#fullAttribute('mixed')), this.avatarId)
        }

        if (element.hasAttribute(this.#fullAttribute('text'))) {
            return this.translator.translateText(element.getAttribute(this.#fullAttribute('text')), this.avatarId)
        }
    }

    #getTarget(element) {
        return element.closest(this.attributes.map(attribute => `[${this.#fullAttribute(attribute)}]`).join(', '))
    }

    #fullAttribute(attribute) {
        return `${this.base}-${attribute}`
    }

}