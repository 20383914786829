import { Avatar } from "../src/avatar"
import Translator from "./translator"
import Attributes from "./attributes"
import Selection from "./selection"
import Hider from "./hider"

export default class {
    constructor(config = {}) {
        const default_config = {
            'baseUrl': null,
            'api': {
                'default': 'https://tech.surdo24.ru/api/v1/translation/translate'
            }
        }
        
        this.config = Object.assign(default_config, config) // merge
    }

    connect() {
        this.translator = new Translator({
            'api': {
                'id': `${this.config.baseUrl}translations/_id_/command.json`,
                'default': this.config.api.default
            }
        })

        const avatarId = 'adaptis-clippit'

        this.attributes = new Attributes(this.translator)
        this.attributes.avatarId = avatarId
        this.attributes.connect()

        this.selection = new Selection(document.getElementById('adaptis-translate-selection'))
        this.selection.connect()

        const domElement = document.getElementById('adaptis-clippit')

        this.avatar = new Avatar({
            id: avatarId,
            domElement: domElement,
            modelUrl: 'assets/3d/model_low.glb',
            baseUrl: this.config.baseUrl
        })

        this.avatar.init()

        this.hider = new Hider(domElement, avatarId)
        this.hider.connect()
    }

    disconnect() {
        this.attributes.disconnect()
        this.hider.disconnect()
        this.avatar.dispose()
    }
}