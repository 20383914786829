import { Quaternion, Vector3 } from "three"

export function mix_auto(weighted_values) {
    if (weighted_values.length === 0) {
        return null
    }

    return weighted_values[0].value.length === 4 ? mix_quaternions(weighted_values) : mix_vectors(weighted_values)
}

export function mix_quaternions(weighted_values) {
    const result = weighted_values.reduce((acc, cur) => {
        acc.weight += cur.weight
        acc.value = acc.value.slerp(new Quaternion(...cur.value), cur.weight / acc.weight)

        return acc
    }, { weight: 0, value: new Quaternion(0, 0, 0, 0) })

    return {
        value: result.value.toArray(),
        weight: result.weight
    }
}

export function mix_vectors(weighted_values) {
    const result = weighted_values.reduce((acc, cur) => {
        acc.weight += cur.weight
        acc.value = acc.value.lerp(new Vector3(...cur.value), cur.weight / acc.weight)

        return acc
    }, { weight: 0, value: new Vector3(0, 0, 0) })

    return {
        value: result.value.toArray(),
        weight: result.weight
    }
}
