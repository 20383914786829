export default class {
    constructor() {
        this.objects = []
        this.last_objects = []
        this.counters = {}
    }

    add(object) {
        if (Array.isArray(object)) {
            object.forEach(obj => {
                this.objects.push(obj)
            })
        } else {
            this.objects.push(object)
        }
    }

    add_last(object) {
        this.last_objects.push(object)
    }

    dispose() {
        this.objects.forEach(object => {
            this.#dispose_object(object)
        })

        this.last_objects.forEach(object => {
            this.#dispose_object(object)
        })
    }

    #dispose_object(object) {
        // if scene
        if (object.isScene) {
            this.#dispose_traversable(object)
            this.#count('scenes')
        }

        if (object.getRenderTarget) {
            object.getRenderTarget()?.dispose()
            this.#count('renderTarget')
        }

        // dispose renderer
        if (object.dispose) {
            object.dispose()
            this.#count(object.constructor.name)
        }

        if (object.domElement) {
            object.domElement.parentNode?.removeChild(object.domElement)
            object.domElement = undefined
            this.#count('domElement')
        }

        object = undefined
    }

    #dispose_traversable(object) {
        if (object?.traverse) {
            object.traverse(child => {
                this.#dispose_children(child, 2)

                if (child.dispose) {
                    child.dispose()
                    this.#count(child.type)
                }

                child = undefined
            })
        }
    }

    #dispose_children(parent, depth=1) {
        for (let key in parent) {
            if (parent[key]?.dispose && (parent[key].dispose instanceof Function)) {
                parent[key].dispose()
                
                // if texture close image
                if (parent[key].isTexture) {
                    parent[key].image?.close()
                }
                
                this.#count(parent[key].type || ('_' + key))
            }

            if (depth > 1) {
                this.#dispose_children(parent[key], depth - 1)
            }
        }
    }

    #count(key) {
        if (!this.counters[key]) this.counters[key] = 0
        this.counters[key] += 1
    }

}