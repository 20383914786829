import { AnimationClip, NumberKeyframeTrack} from 'three'

export default class {
    async loadAsync(url) {
        const response = await fetch(url)
        if (!response.ok) {
            console.error(`Could not load asset ${url}: ${response.statusText}`)
        }

        try {
            const shapes = await response.json()
            const morfTarget = shapes['morfTarget']

            const tracks = []
            for (let number in shapes['values']) {
                tracks.push(new NumberKeyframeTrack(
                    morfTarget + '.morphTargetInfluences[' + number + ']',
                    shapes['times'],
                    shapes['values'][number]
                ))
            }

            return { // emulate glb resource
                animations: [
                    new AnimationClip(url, -1, tracks)
                ]
            }
        } catch (e) {
            console.error(`Could not parse asset ${url}: ${e}`)
        }
    }
}
