import { mix_auto } from "./mixing"

export function loopClip(clip, overlap_duration) {
    for (const track of clip.tracks) {
        loopTrack(track, overlap_duration)
    }
}

export function loopTrack(track, overlap_duration) {
    const start = track.times[0]
    const end = track.times[track.times.length - 1]
    
    const values = Array.from(track.values)
    const start_value = values.slice(0, track.getValueSize())
    const end_value = values.slice(values.length - track.getValueSize())

    for (let i = 0; i < track.times.length; i++) {
        const time = track.times[i]
        const value = values.slice(i * track.getValueSize(), (i+1) * track.getValueSize())

        // determine mixture level and if we deal with start or end
        const distance = Math.min(time - start, end - time)
        const mix_frame = (time <= (end - start) / 2) ? end_value : start_value

        // skip inner frames outside of desired overlap
        if (distance > overlap_duration) {
            continue
        }

        // create blended with the opposite end frame
        const weight = (distance + overlap_duration) / (overlap_duration * 2)
        const new_frame = mix_auto([
            {
                value: value,
                weight: weight
            },
            {
                value: mix_frame,
                weight: 1 - weight
            }
        ])

        // replace a frame with a mixed frame
        for (let j = 0; j < new_frame.value.length; j++) {
            track.values[i * track.getValueSize() + j] = new_frame.value[j]
        }
    }
}