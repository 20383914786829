export default class {
    constructor(avatarId, player) {
        this.avatarId = avatarId
        this.player = player

        const actions = ['freeze', 'unfreeze']
        this.listeners = {}

        for (let action of actions) {
            this.listeners[action] = this[action].bind(this)
            document.addEventListener(`adaptis.avatar.rendering.${action}`, this.listeners[action])
        }
    }

    freeze(e) {
        if (e.detail.avatarId == this.avatarId) {
            this.player.renderingEnabled = false
        }
    }

    unfreeze(e) {
        if (e.detail.avatarId == this.avatarId) {
            this.player.renderingEnabled = true
        }
    }

    dispose() {
        for (let action of Object.keys(this.listeners)) {
            document.removeEventListener(`adaptis.avatar.rendering.${action}`, this.listeners[action])
        }
    }
}