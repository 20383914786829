export default class {
    constructor(avatarId, processor) {
        this.avatarId = avatarId
        this.processor = processor

        const actions = ['play', 'enqueue', 'idle']
        this.listeners = {}

        for (let action of actions) {
            this.listeners[action] = this[action].bind(this)
            document.addEventListener(`adaptis.avatar.${action}`, this.listeners[action])
        }
    }

    play(e) {
        if (e.detail.avatarId == this.avatarId) {
            this.processor.process(e.detail.data)
        }
    }

    enqueue(e) {
        if (e.detail.avatarId == this.avatarId) {
            this.processor.enqueue(e.detail.data)
        }
    }

    idle(e) {
        if (e.detail.avatarId == this.avatarId) {
            this.processor.reset()
        }
    }

    dispose() {
        for (let action of Object.keys(this.listeners)) {
            document.removeEventListener(`adaptis.avatar.${action}`, this.listeners[action])
        }
    }
}