import ParallelSlot from "../timeline/parallel"

export default class {
    constructor(params={ animator: null, subtitler: null, ticker: null, assets: null }) {
        this.animator = params.animator
        this.subtitler = params.subtitler
        this.ticker = params.ticker
        this.assets = params.assets

        this.ids = new Set()
    }

    async process(command) {
        this.reset()
        return await this.enqueue(command) 
    }

    async enqueue(command) {
        // create uuid
        const id = crypto.randomUUID()
        const slot = new ParallelSlot(id)

        this.ids.add(id)

        if (command.animations) {
            // preload all animations
            let urls = []
            for (const name in command.animations) {
                urls.push(command.animations[name][0].animation.url)
            }

            await (Promise.all([...new Set(urls)].map((url) => this.assets.get(url))))

            for (const name in command.animations) {
                const animation = await this.assets.get(command.animations[name][0].animation.url)
                const clip = animation?.animations[0]?.clone()

                if (clip) {
                    slot.push(
                        await this.animator.addAnimation(
                            id + '-animation-' + name,
                            clip
                        )
                    )
                }
            }
        }

        if (command.subtitles) {
            slot.push(await this.subtitler.addSubtitles(id, command.subtitles))
        }

        if (this.ids.has(id)) {
            this.ticker.slot.push(slot)
        }

        return id
     }

    reset() {
        this.ticker.reset()
        this.ids.clear()
    }

    dispose() {
        this.animator.dispose()
        this.subtitler.dispose()
        this.ticker.dispose()
    }
}
