export default class {
    constructor(avatar_id, slot) {
        this.avatar_id = avatar_id
        this.slot = slot

        this.slot.addEventListener('in', this.in.bind(this))
        this.slot.addEventListener('out', this.out.bind(this))
    }

    in() {
        this.emit('adaptis.avatar.activity.playing')
    }

    out() {
        this.emit('adaptis.avatar.activity.idle')
    }

    emit(name) {
        document.dispatchEvent(new CustomEvent(name, { bubbles: false,
            detail: {
                avatar_id: this.avatar_id
            }
         }))
    }

    dispose() {
        this.slot.removeEventListener('in', this.in.bind(this))
        this.slot.removeEventListener('out', this.out.bind(this))
    }
}
