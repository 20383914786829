import {
    AnimationMixer,
    Clock,
} from 'three'

export default class extends EventTarget {
    constructor(scene) {
        super()

        this.scene = scene
        this.mixer = new AnimationMixer(this.scene.scene)
        this.clock = new Clock()

        this.mixer.timeScale = 1.0
        this.renderingEnabled = true
    }

    play() {
        this.scene.renderer.setAnimationLoop(() => {
            if (this.renderingEnabled) {
                this.mixer.update(this.clock.getDelta())
                this.scene.render()
            }

            this.dispatchEvent(new CustomEvent('tick', { detail: { time: this.clock.getElapsedTime() } })) // TODO: sync with mixer time/timescale
        })
    }

    stop() {
        this.scene.renderer.setAnimationLoop(null)
        this.clock.stop()
    }

    dispose() {
        this.scene.renderer.setAnimationLoop(null)
        this.mixer.stopAllAction()
        this.mixer.uncacheRoot(this.mixer.getRoot())
    }
}