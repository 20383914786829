import { LoadingManager } from 'three'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import Disposer from './disposer'
import BlendShapesLoader from './loaders/blendshapes'

export default class {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
        this.assets = {}
        this.loaders = {
            'glb': this.createGLTFLoader(),
            'json': new BlendShapesLoader()
        }
        this.disposer = new Disposer()
    }

    async get(url) {
        url = url.trim()

        
        if (this.baseUrl && !url.includes('//')) { // means it is not full url, http://, https:// or //
            url = this.baseUrl + url
        }

        if (url in this.assets) {
            return this.assets[url]
        }
        
        const loader = this.loaders[url.split('?').shift().split('.').pop()] // get extension: part before GET params, then after dot
        
        try {
            this.assets[url] = (await loader?.loadAsync(url)) ?? undefined
            this.disposer?.add(this.assets[url])
        } catch (e) {
            this.assets[url] = undefined
            console.warn('Assets loading error:', url)
        }

        return this.assets[url]
    }

    createGLTFLoader() {
        const manager = new LoadingManager()
        manager.on_error = (e) => {
            console.error('Assets GLTFLoader error:', e)
        }

        return new GLTFLoader(manager)
    }

    dispose() {
        this.manager = undefined
        this.loader = undefined
        this.disposer.dispose()
    }
}
