export default class {
    constructor(domElement) {
        this.listeneres = {
            'selectionchange': this.selectionchange.bind(this)
        }

        this.domElement = domElement
    }

    connect() {
        if (this.domElement) {
            document.addEventListener('selectionchange', this.listeneres['selectionchange'])
        }
    }

    disconnect() {
        document.removeEventListener('selectionchange', this.listeneres['selectionchange'])
    }

    selectionchange(e) {
        const selection = window.getSelection().toString()
        this.domElement.setAttribute('data-adaptis-translation-text', selection)

        if (selection) {
            this.domElement.style.pointerEvents = 'auto'
            this.domElement.style.opacity = 1
        } else {
            this.domElement.style.pointerEvents = 'none'
            this.domElement.style.opacity = 0
        }

    }

}