export default class {
    constructor(slot, tick_emitter) {
        this.slot = slot
        this.time_shift = 0
        this.tick_emitter = tick_emitter

        this.tick_emitter.addEventListener('tick', (event) => {
            this.tick(event.detail.time)       
        })
    }

    tick(time) {
        if (this.time_shift === Infinity) {
            this.time_shift = time
        }

        if (time - this.time_shift > this.slot.getDuration()) {
            // pause at the end of a slot until it is extended
            this.time_shift = time - this.slot.getDuration()
        }

        this.slot.tick(time - this.time_shift)
    }

    reset() {
        this.time_shift = Infinity
        this.slot.reset()
    }
}