import FixedSlot from "../../timeline/fixed"
import ParallelSlot from "../../timeline/parallel"

export default class {
    constructor(avatarId) {
        this.avatarId = avatarId
        this.subtitles = {}
        this.activeSubtitles = new Set()
    }

    addSubtitles(id, subtitles) {
        const subtitlesSlot = new ParallelSlot(id)

        for (const subtitle of subtitles) {            
            const slotId = subtitle.id

            this.subtitles[slotId] = {
                text: subtitle.text
            }

            const slot = new FixedSlot(slotId, subtitle.delay, subtitle.duration)
            
            subtitlesSlot.push(slot)

            slot.addEventListener('in', this.in.bind(this))
            slot.addEventListener('out', this.out.bind(this))
            slot.addEventListener('reset', this.out.bind(this))
        }

        return subtitlesSlot
    }

    in(event) {
        const subtitle = this.subtitles[event.detail.slot.id]
        this.activeSubtitles.add(event.detail.slot.id)

        document.dispatchEvent(new CustomEvent('adaptis.avatar.subtitle.show', {
            bubbles: false,
            detail: {
                'avatarId': this.avatarId,
                'id': event.detail.slot.id,
                'subtitle': subtitle
            }
        }))
    }

    out(event) {
        this.activeSubtitles.delete(event.detail.slot.id)
        document.dispatchEvent(new CustomEvent('adaptis.avatar.subtitle.hide', {
            bubbles: false,
            detail: {
                'avatarId': this.avatarId,
                'id': event.detail.slot.id
            }
        }))
    }

    dispose() {
        for (const slot of Object.values(this.subtitles)) {
            slot.removeEventListener('in', this.in.bind(this))
            slot.removeEventListener('out', this.out.bind(this))
            slot.removeEventListener('reset', this.out.bind(this))
        }

        this.subtitles = undefined
        this.activeSubtitles = undefined
        this.event_target = undefined
    }
}
