import { 
  WebGLRenderer, Scene,
  Fog,
  PerspectiveCamera,
  AmbientLight, DirectionalLight, DirectionalLightHelper, PointLight, PointLightHelper,
  PCFSoftShadowMap, CustomToneMapping,
} from "three"

import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import {
  EffectComposer,
  FXAAShader, GammaCorrectionShader, GTAOPass,
  HDRCubeTextureLoader,
  RenderPass,
  RGBELoader, SAOPass,
  ShaderPass, SSAOPass,
  UnrealBloomPass
} from "three/addons";

import Disposer from "./disposer"

export default class {
  constructor() {
    // read get parameter from current url
    this.debug = (new URLSearchParams(location.search)).has('debug')
    this.disposer = new Disposer()

    this.create_scene()
  }

  create_scene() {
    this.scene = new Scene()
    this.focus_point = 1.5
    
    this.setup_renderer()
    this.setup_camera()

    // scene beauty
    this.scene.fog = new Fog(this.scene.background, 1, 5000)

    this.add_lights()
    // if get parameter hd is in url
    if ((new URLSearchParams(location.search)).has('hd')) {
      this.add_postprocessing()
    }

    this.disposer.add(this.scene)
  }

  setup_renderer() {
    // setup renderer
    this.renderer = new WebGLRenderer({ antialias: true, alpha: true })
    this.disposer.add_last(this.renderer)
    
    //add shadow contrast
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = PCFSoftShadowMap
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.toneMapping = CustomToneMapping
    this.renderer.toneMappingExposure = 1

    if (this.debug) {
      console.log(this.renderer.capabilities)
      console.log(this.renderer.info)
    }
  }

  setup_camera() {
    // setup camera and light
    this.camera = new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000)
    
    const position = [0.15, this.focus_point + 0.25, 0.75]
    const lookAt = [0, this.focus_point, 0]
    
    this.camera.position.set(position[0], position[1], position[2])
    this.camera.lookAt(lookAt[0], lookAt[1], lookAt[2])

    if (this.debug) {
      const orbit = new OrbitControls(this.camera, this.renderer.domElement)
      this.camera.position.set(position[0], position[1], position[2])
      orbit.target.set(lookAt[0], lookAt[1], lookAt[2])
      orbit.update()
    }

    this.disposer.add(this.camera)
  }

  add_lights() {
    const shadowMapSize = 512

    this.scene_lights = {}
    this.scene_lights_colors = {}
    this.scene_lights_helpers = {}

    // Key light - основной источник света, направленный на лицо модели
    this.scene_lights_colors.key_light = 0xffffff;
    
    this.scene_lights.key_light = new DirectionalLight(this.scene_lights_colors.key_light, 4);
    this.scene_lights.key_light.position.set(0, this.focus_point + 1.5, 4);
    this.scene_lights.key_light.target.position.set(0, this.focus_point, 0);

    this.scene.add(this.scene_lights.key_light);

    if (this.debug) {
        this.scene_lights_helpers.key_light = new DirectionalLightHelper(this.scene_lights.key_light, 1);
        this.scene.add(this.scene_lights_helpers.key_light);
    }

    // Fill light - мягкий свет для заполнения теней с другой стороны
    this.scene_lights_colors.fill_light = 0xffffff;
    this.scene_lights.fill_light = new DirectionalLight(this.scene_lights_colors.fill_light, 0.2);
    this.scene_lights.fill_light.position.set(0, this.focus_point, -4);
    this.scene_lights.fill_light.target.position.set(0, this.focus_point, 0);

    this.scene.add(this.scene_lights.fill_light);

    if (this.debug) {
      this.scene_lights_helpers.fill_light = new DirectionalLightHelper(this.scene_lights.fill_light, 1);
        this.scene.add(this.scene_lights_helpers.fill_light);
    }

    // Rim light - свет сзади для выделения контуров рук
    this.scene_lights_colors.rim_light = 0xff0000;
    this.scene_lights.rim_light = new PointLight(this.scene_lights_colors.rim_light, 2);
    this.scene_lights.rim_light.position.set(0, this.focus_point + 1, -2);

    this.scene.add(this.scene_lights.rim_light);

    if (this.debug) {
      this.scene_lights_helpers.rim_light = new PointLightHelper(this.scene_lights.rim_light, 1);
        this.scene.add(this.scene_lights_helpers.rim_light);
    }

    // Side lights - боковые источники света с обеих сторон модели
    this.scene_lights_colors.left_side_light = 0xcfd9ff;
    if (!this.scene_lights.side_light_left) {
        this.scene_lights.side_light_left = new DirectionalLight(this.scene_lights_colors.left_side_light, 1);
    }
    this.scene_lights.side_light_left.position.set(-4, this.focus_point, 0);
    this.scene_lights.side_light_left.target.position.set(0, this.focus_point, 0);

    this.scene.add(this.scene_lights.side_light_left);

    if (this.debug && !this.side_light_left_helper) {
        this.side_light_left_helper = new DirectionalLightHelper(this.scene_lights.side_light_left, 1);
        this.scene.add(this.side_light_left_helper);
    }

    this.scene_lights_colors.right_side_light = 0xfcdabc;
    this.scene_lights.side_light_right = new DirectionalLight(this.scene_lights_colors.right_side_light, 1);
    this.scene_lights.side_light_right.position.set(4, this.focus_point, 0);
    this.scene_lights.side_light_right.target.position.set(0, this.focus_point, 0);

    this.scene.add(this.scene_lights.side_light_right);

    if (this.debug) {
      this.scene_lights_helpers.side_light_right = new DirectionalLightHelper(this.scene_lights.side_light_right, 1);
      this.scene.add(this.scene_lights_helpers.side_light_right);
    }

    // Ambient light - окружающее освещение для общего освещения сцены
    this.scene_lights.ambient_light = new AmbientLight(0x404040); // тёмное окружающее освещение
    this.scene.add(this.scene_lights.ambient_light);

    for (let key in this.scene_lights) {
      if (this.scene_lights[key].shadow) {
        this.scene_lights[key].castShadow = true;
        this.scene_lights[key].shadow.mapSize.width = shadowMapSize;
        this.scene_lights[key].shadow.mapSize.height = shadowMapSize;
      }
    }

    this.disposer.add(this.scene_lights)
    this.disposer.add(this.scene_lights_helpers)
  }

  add_postprocessing() {
    this.composer = new EffectComposer(this.renderer);
    const renderPass = new RenderPass(this.scene, this.camera);
    const gtaoPass = new GTAOPass(this.scene, this.camera);
    const FXAAPass = new ShaderPass(FXAAShader);
    const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);

    gtaoPass.output = GTAOPass.OUTPUT.Denoise;
    // gtaoPass settings
    const aoParameters = {
        radius: 0.14,
        distanceExponent: 1.,
        thickness: 1,
        scale: 1,
        samples: 32,
        distanceFallOff: 0.5,
        screenSpaceRadius: false,
    };
    const pdParameters = {
        lumaPhi: 20.,
        depthPhi: 2.,
        normalPhi: 20.,
        radius: 4.,
        radiusExponent: 1.,
        rings: 2.,
        samples: 16,
    };

    //FXAAPass settings
    FXAAPass.uniforms['resolution'].value.x = 1 / window.innerWidth;
    FXAAPass.uniforms['resolution'].value.y = 1 / window.innerHeight;

    gtaoPass.updateGtaoMaterial( aoParameters );
    gtaoPass.updatePdMaterial( pdParameters );
    
    this.composer.addPass(renderPass);
    this.composer.addPass(gtaoPass);
    this.composer.addPass(FXAAPass);
    this.composer.addPass(gammaCorrectionPass);

    this.disposer.add(this.composer)
  }

  render() { this.renderer.render(this.scene, this.camera) }

  add_scene(scene) {
    if (scene.isObject3D) {
      scene.receiveShadow = true
      scene.castShadow = true
    }

    this.scene.add(scene)
    this.render()
  }

  addToElement(element) {
    element.appendChild(this.renderer.domElement)
    this.updateSize(element)

    this.resize_observer = new ResizeObserver(() => {
      this.updateSize(element)
    })

    this.resize_observer.observe(element)
  }

  updateSize(element) {
    this.renderer.setSize(element.clientWidth, element.clientHeight)

    this.camera.aspect = element.clientWidth / element.clientHeight
    this.camera.updateProjectionMatrix()

    this.render()
  }

  dispose() {
    this.resize_observer.disconnect()

    // force context loss
    this.renderer.context?.getExtension('WEBGL_lose_context')?.loseContext()
    this.disposer.dispose()
  }
}
