import {
    AnimationAction,
    LoopOnce,
} from "three"

import { loopClip } from "./looping"
import FixedSlot from "../timeline/fixed"
import Disposer from "../3d/disposer"

export default class {
    constructor(mixer) {
        this.disposer = new Disposer()

        this.mixer = mixer

        this.idle_animation = null

        this.slots = []

        this.animations = {}
        this.active_animations = new Set()

        this.fading_duration = 0.25
    }

    async addAnimation(id, clip) {
        const action = new AnimationAction(this.mixer, clip)
        action.loop = LoopOnce
        action.clampWhenFinished = true

        this.disposer.add(action)

        this.animations[id] = action
        const slot = new FixedSlot(id, 0, action.getClip().duration)
        
        slot.addEventListener('in', this.in.bind(this))
        slot.addEventListener('out', this.out.bind(this))
        slot.addEventListener('reset', this.reset.bind(this))

        this.slots.push(slot)

        return slot
    }

    setIdleAnimation(clip) {
        loopClip(clip, this.fading_duration)
        this.idle_animation = new AnimationAction(this.mixer, clip)

        this.disposer.add(this.idle_animation)

        if (this.active_animations.size == 0) {
            this.fadeIn(this.idle_animation)
        }
    }

    fadeIn(action, time=0) {
        action.fadeIn(this.fading_duration).play()
        action.enabled = true
        action.time = time
    }

    fadeOut(action) {
        action.fadeOut(this.fading_duration)
    }

    in(event) {
        if (this.active_animations.size == 0) {
            this.fadeOut(this.idle_animation)
        }

        this.active_animations.add(event.detail.slot.id)
        this.fadeIn(this.animations[event.detail.slot.id], event.detail.time)
    }

    out(event) {
        this.fadeOut(this.animations[event.detail.slot.id])
        this.active_animations.delete(event.detail.slot.id)
        
        if (this.active_animations.size == 0) {
            this.fadeIn(this.idle_animation)
        }
    }

    reset(event) {
        this.out(event)
        this.active_animations.delete(event.detail.slot.id)
        delete this.animations[event.detail.slot.id]
    }

    dispose() {
        this.mixer.stopAllAction()

        for (const slot of this.slots) {
            slot.removeEventListener('in', this.in.bind(this))
            slot.removeEventListener('out', this.out.bind(this))
            slot.removeEventListener('reset', this.reset.bind(this))
        }

        this.mixer.uncacheAction(this.idle_animation)
        for (const animation of Object.values(this.animations)) {
            this.mixer.uncacheAction(animation)
        }

        this.disposer.dispose()
    }

}
